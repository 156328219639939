import Network from '@/services/network'
import {ref} from 'vue'


export default function handleCBusinesses(){
    const network = new Network;
    const loading = ref(false)

    const fetchBusinessList = (query) => {
        return network.api('get', `/business` + query);
    }

    const createNewBusiness = (data) => {
        return network.api('post', '/business', data);
    }

    const fetchSingleBusiness = (businessId, companyId) => {
        return network.api('get', `/business/` + businessId + `?company_id=` + companyId);
    }

    const updateBusiness = (id, data) => {
        return network.api('post', '/business/update/' + id, data);
    }

    return {
        fetchBusinessList,
        createNewBusiness,
        fetchSingleBusiness,
        updateBusiness,
        loading
    }
}