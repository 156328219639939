import Network from '@/services/network'
import { ref } from 'vue'

export default function handleAccounting() {
    const network = new Network;
    const accountingLoading = ref(false)

    const getBalanceSheet = (query) => {
      return network.api('get', '/accounting/balance-sheet'+ query);
    }

    const getProfitLoss = (query) => {
      return network.api('get', '/accounting/profit-and-loss'+ query);
    }

    return {
        getBalanceSheet,
        getProfitLoss,
        accountingLoading
    }
}
