<template>
  <div class="card" style="min-height: calc(100vh - 158px)">
    <div class="p-2 bg-blue-light">
      <TitleButton :showBtn="false" title="Profit Loss" />
      <DateQuerySetter class="mt-2" btnTitle="Go" @onClickGo="onClickGo">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Business</label>
          <v-select
            placeholder="Select Business"
            class="w-100"
            :options="businesses"
            label="name"
            :reduce="(name) => name.id"
            v-model="business_id"
          />
        </div>
        <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
          <label for="colFormLabel" class="col-form-label">Party</label>
          <v-select
              placeholder="Select Party"
              class="w-100"
              :options="contactProfiles"
              label="name"
              :reduce="name => name.id"
              v-model="contact_profile_id"
          />
        </div>
      </DateQuerySetter>
    </div>

    <div class="mt-2"></div>
    <div class="">
      <button class="btn btn-success float-end me-4" @click="exportProfitLoss">Export</button>
    </div>
    <div
      :style="{
        opacity: accountingLoading ? 0.5 : 1,
        maxWidth: '640px',
        width: '100%',
        zIndex: 10,
      }"
      class="d-flex justify-content-between bordered bg-white sticky py-1 px-2 border-t border-b mx-auto"
    >
      <strong class="font-medium-2">Particulars</strong>
      <strong class="font-medium-2">Amount</strong>
    </div>
    <!-- income -->
    <div
      :style="{
        opacity: accountingLoading ? 0.5 : 1,
        maxWidth: '640px',
        width: '100%',
      }"
      class="card px-2 mx-auto mt-1"
    >
      <span> Income </span>
      <BalanceSheetTree
        v-for="(assetItem, i) in income"
        :key="i"
        :node="assetItem"
      />
      <div class="d-flex justify-content-between border-b-2-dark node-hover">
        <strong>TOTAL INCOME</strong>
        <strong>Tk. {{ commaFormat(getTotal(income)) }}</strong>
      </div>
    </div>
    <!-- income -->

    <!-- expense -->
    <div
      :style="{
        opacity: accountingLoading ? 0.5 : 1,
        maxWidth: '640px',
        width: '100%',
      }"
      class="card px-2 mx-auto"
    >
      <span> Expense </span>
      <BalanceSheetTree
        v-for="(equityItem, i) in expense"
        :key="i"
        :node="equityItem"
      />
      <div class="d-flex justify-content-between border-b-2-dark node-hover">
        <strong>TOTAL EXPENSE</strong>
        <strong>Tk. {{ commaFormat(getTotal(expense)) }}</strong>
      </div>
      <div
        class="d-flex justify-content-between border-b-2-dark node-hover mt-2"
      >
        <strong>NET PROFIT / (LOSS)</strong>
        <strong
          >Tk. {{ commaFormat(getTotal(income) - getTotal(expense)) }}</strong
        >
      </div>
    </div>
    <!-- expense -->
    <Loader v-if="accountingLoading" />
  </div>
</template>

<script>
import figureFormatter from "@/services/utils/figureFormatter";
import handleAccounting from "@/services/modules/accounting";
import { inject } from "vue";

import TitleButton from "@/components/atom/TitleButton";
import Loader from "@/components/atom/LoaderComponent";
import DateQuerySetter from "@/components/atom/DateQuerySetter";
import BalanceSheetTree from "@/components/molecule/company/balance-sheet/BalanceSheetTree";
import handleCBusinesses from "@/services/modules/businesses";
import handleContact from '@/services/modules/contact'

export default {
  name: "BalanceSheet",

  components: {
    TitleButton,
    DateQuerySetter,
    BalanceSheetTree,
    Loader,
  },

  data: () => ({
    businesses: [],
    contactProfiles: [],
    business_id: null,
    contact_profile_id: null,
    balanceSheetData: [],
  }),

  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
    start() {
      return this.$route.query.start;
    },
    end() {
      return this.$route.query.end;
    },
    income() {
      return this.balanceSheetData.income ? this.balanceSheetData.income : [];
    },
    expense() {
      return this.balanceSheetData.expense ? this.balanceSheetData.expense : [];
    },
    token() {
      return localStorage.getItem('token')
    }
  },

  methods: {
    exportProfitLoss() {
      let url = `${process.env.VUE_APP_BASE_URL}/export/accounting/profit-and-loss` + this.getQuery() + `&_token=${this.token}`;
      let a = document.createElement("a")
      a.setAttribute("href", url)
      a.click()
    },
    getTotal(data) {
      let total = 0;
      data.map((i) => {
        total += i.total;
      });
      return total;
    },
    onClickGo() {
      this.getDateWiseProfitLoss(this.getQuery());
    },
    getQuery() {
      let query = "?company_id=" + this.companyId;
      if (!this.start && !this.end) return query;
      if (this.start) query += "&start_date=" + this.start;
      if (this.end) query += "&end_date=" + this.end;
      if (this.business_id) query += "&business_id=" + this.business_id;
      if (this.contact_profile_id) query += "&contact_profile_id=" + this.contact_profile_id;
      // query += '&offset='+this.offset
      return query;
    },
    async getDateWiseProfitLoss(query = "") {
      try {
        this.accountingLoading = true;
        let res = await this.getProfitLoss(query);
        if (!res.status) {
          this.showError(res.response.message);
        }
        if (res.status) {
          this.balanceSheetData = res.data;
        }
      } catch (err) {
        if (!err.response) {
          this.showError("Something is wrong. Check your connectivity!!");
        }
        if (err.response?.data) {
          this.showError(err.response.data.message);
          return;
        }
      } finally {
        this.accountingLoading = false;
      }
    },

    async getBusinesses() {
      try {
        let companyId = this.$route.params.companyId;
        let res = await this.fetchBusinessList("?company_id=" + companyId);

        if (!res.status) {
        }
        if (res.status) {
          this.businesses = res.data;
        }
      } catch (err) {
        if (!err.response) {
          this.showError("Something is wrong. Check your connectivity!!");
        }
        if (err.response) {
          this.showError(err.response.message);
        }
      } finally {
        this.loading = false;
      }
    },
    async getContactProfiles() {
      try {
        let companyId = this.$route.params.companyId
        let contactId = '';
        let q = '';
        let query = '?company_id=' + companyId + '&q=' + q + '&contact_id' + contactId;
        let res = await this.fetchContactProfiles(query)

        if(!res.status) {
        }
        if(res.status) {
          this.contactProfiles = res.data;
        }

      } catch (err) {
        if(!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        }
        if(err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.loading = false
      }
    },
  },

  setup() {
    const showError = inject("showError");
    const showSuccess = inject("showSuccess");
    // const store = useStore()

    const { accountingLoading, getProfitLoss } = handleAccounting();

    const { commaFormat } = figureFormatter();

    const { fetchBusinessList } = handleCBusinesses();
    const { fetchContactProfiles } = handleContact();

    return {
      getProfitLoss,
      fetchBusinessList,
      fetchContactProfiles,
      accountingLoading,
      commaFormat,
      showError,
      showSuccess,
    };
  },

  mounted() {
    // this.getDateWiseBalanceSheet(this.getQuery())
    this.getDateWiseProfitLoss(this.getQuery());
    this.getBusinesses();
    this.getContactProfiles()
  },
};
</script>

<style scoped>
span {
  font-size: 1.2em;
}
</style>
